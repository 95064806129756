import { useState, useMemo } from "react";

// react-router components
import { Routes, Route } from "react-router-dom";

import { loadPhotosJson } from "./utilities";

import Home from "./pages/Home";
import Category from "./pages/Category";
import Photos from "./pages/Photos";
import AboutMe from "./pages/AboutMe";
import PolicyBanner from "./components/PolicyBanner";
import AdminPanel from "./pages/AdminPanel";
// import Cv from "./pages/Cv";
// import Page404 from "./pages/Page404/404.html";


// const Page404 = require("./pages/Page404");

function App() {
	const [cities, setCities] = useState([]);
	const [states, setStates] = useState([]);
	const [years, setYears] = useState([]);

	// useEffect(() => {
	// 	(async () => {
	// 		try {
	// 			const registration = await navigator.serviceWorker.register("./serviceworker.js", {
	// 				scope: "/",
	// 			});
	// 			if (registration.installing) {
	// 				console.log("Service worker installing");
	// 			} else if (registration.waiting) {
	// 				console.log("Service worker installed");
	// 			} else if (registration.active) {
	// 				console.log("Service worker active");
	// 				// registration.ciao();
	// 			}
	// 		} catch (error) {
	// 			console.error(`Registration failed with ${error}`);
	// 		}
	// 	})();
	// }, []);

	useMemo(() => {
		const jsonPhotoOrigin = loadPhotosJson();

		setCities([]);
		setStates([]);
		setYears([]);

		const cities = [];
		const states = [];
		const years = [];

		jsonPhotoOrigin.foto.map((photo) => {
			if (!cities.includes(photo.city)) cities.push(photo.city);
			if (!states.includes(photo.state)) states.push(photo.state);
			if (!years.includes(photo.year)) years.push(photo.year);
		});

		//li salvo nel session storage
		window.sessionStorage.setItem("all_cities", cities);
		window.sessionStorage.setItem("all_states", states);
		window.sessionStorage.setItem("all_years", years);

		setCities(cities);
		setStates(states);
		setYears(years);
	}, []);

	return (
		<>
			<Routes>
				<Route path="/" element={<Home title={process.env.REACT_APP_APP_NAME} />} />
				<Route path="/home" element={<Home title={process.env.REACT_APP_APP_NAME} />} />
				<Route path="/photos/category/:category" element={<Category title={`${process.env.REACT_APP_APP_NAME}`} />} />
				<Route path="/category/all" element={<Category title={`${process.env.REACT_APP_APP_NAME}`} />} />
				<Route path="/photos/:params" element={<Photos title={`${process.env.REACT_APP_APP_NAME}`} />} />
				<Route path="/about-me" element={<AboutMe title={`${process.env.REACT_APP_APP_NAME}`} />} />
				<Route path="/admin/panel" element={<AdminPanel title={`${process.env.REACT_APP_APP_NAME}`} />} />
				{/* <Route path="/cv/es" element={<Cv title={`${process.env.REACT_APP_APP_NAME}`} />} /> */}

				{/* <Route path="*" element={<Home />} /> */}
				{/* <Route path="/serviceWorker" element={serviceWorker} /> */}
			</Routes>
			<PolicyBanner />
		</>
	);
}

export default App;
